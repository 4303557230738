import { LocationProvider } from '@reach/router';
import { BrowserRouter } from 'react-router-dom';

import { RedirectCatcher } from '@xing-com/crate-core-router';

import { BasePath } from '../basepath';
import type { RootComponentType } from '../root';

export const BrowserRoot: RootComponentType = ({ basePath, children }) => {
  return (
    <LocationProvider>
      <RedirectCatcher>
        <BasePath basepath={basePath}>
          <BrowserRouter basename={basePath}>{children}</BrowserRouter>
        </BasePath>
      </RedirectCatcher>
    </LocationProvider>
  );
};
